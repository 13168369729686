// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import login from './login'
import app from './app'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  login,
  app
})

export default rootReducer
